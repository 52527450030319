<template>
  <a-comp v-if="!apg.isEdit()" v-bind='{ apg }' :key='pgid' />
  <component v-if="apg.isEdit() && apg.inBrowser()" :is="Editor" />
</template>

<script setup>
import { computed, ref, watch, defineAsyncComponent } from 'vue'
import { useAppeggio } from '../../app/useAppeggio.js'

const Editor = defineAsyncComponent({ loader: () => import('../../edit/Editor.vue') })
const apg = useAppeggio()
const pgKey = computed(_ => apg.pageKey())
const pgResize = computed(_ => apg.pageResized())
const pgid = ref(apg.pageKey() + pgResize.value)

watch(pgResize, v => {
  pgid.value = pgKey.value + v
}, { immediate: true })

watch(pgKey, v => {
  pgid.value = pgResize.value + v
}, { immediate: true })
</script>
